<template>
  <ls-modal :visible="true" @close="$emit('close')">
    <template #content>
      <h2 id="dialog_label" class="text-med">Join Template</h2>
      <div class="modal-form-field">
        <label for="join-a-template-modal-code" class="form-label bold">
          Enter a code to join a template
        </label>
        <input class="input" id="join-a-template-modal-code" type="text" v-model="code" />
        <p v-if="errorText" class="error-text">{{ errorText }}</p>
      </div>
    </template>
    <template #footer>
      <button class="button" @click="joinTemplate" :disabled="!allowedToJoin">Join</button>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";
import TemplateService from "services/template";
import { mapActions, mapMutations, mapState } from "vuex";
import { SET_ALL_TEMPLATES } from "../../../modules/template/store/mutations";

export default {
  components: {
    "ls-modal": Modal,
  },
  data() {
    return {
      retrieving: false,
      code: "",
      errorText: null,
    };
  },
  computed: {
    ...mapState("template", { allTemplates: "allTemplates" }),
    allowedToJoin() {
      let retriving = this.retrieving;
      let code = this.code;
      return !retriving && code != "";
    },
  },
  watch: {
    code() {
      this.errorText = null;
    },
  },
  methods: {
    ...mapActions("template", {
      getAllTemplateChecklistItems: "getAllTemplateChecklistItems",
      getAllTemplateChecklistGroups: "getAllTemplateChecklistGroups",
      getTemplates: "getTemplates",
    }),
    ...mapMutations("template", {
      setTemplates: SET_ALL_TEMPLATES,
    }),
    joinTemplate() {
      this.retrieving = true;
      TemplateService.join(this.code)
        .then((response) => {
          if (response.status == 200) {
            const allTemplates = this.allTemplates;
            const newTemplate = response.data;

            if (!this.allTemplates.some((t) => t.id === newTemplate.id)) {
              this.setTemplates([...allTemplates, newTemplate]);
            }
            this.getAllTemplateChecklistItems();
            this.getAllTemplateChecklistGroups();
            this.getTemplates();
            this.$emit("joined-template", newTemplate);
          }
        })
        .catch((response) => {
          if (response.status == 404) {
            this.errorText = "Template not found. Verify that your code is correct.";
          } else if (response.status == 429) {
            this.errorText = "Too many attempts to join. Please try again later.";
          }
        })
        .finally(() => {
          this.retrieving = false;
        });
    },
  },
};
</script>
